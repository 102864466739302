import React from "react";
import { List, useRecordContext } from "react-admin";
import Typography from "@mui/material/Grid";
import { MAIN_COLOR } from "../../utils/colors";

const CustomNestedList = (props) => {
  console.log(props);
  const record = useRecordContext();
  return (
    <>
      {props.title ? (
        <Typography component="h3" color={MAIN_COLOR}>
          {props.title}
        </Typography>
      ) : null}
      <List
        resource={props.resource}
        filter={{
          [props.source]: record?.id,
          sort: "-createdAt",
          ...props.filter,
        }}
        actions={props.actions}
      >
        {props.children}
      </List>
    </>
  );
};

export default CustomNestedList;
